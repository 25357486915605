import { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from "clsx";
import { Link, useParams, useLocation, useHistory, matchPath } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { permissionHelper, authHelper, contractHelper, edmsRegisterHelper } from 'helpers';
import SimpleMenu from 'Common/Menu';
import DocumentLibrary from './DocumentLibrary';
import DocumentType from './DocumentType';
import { selectMenuOpen, toggleMenuOpen } from 'redux/slice/navigationSlice';
import { selectContractData, setContract } from 'redux/slice/contractSlice';
import { setLoading } from 'redux/slice/loadingSlice';

import { moduleMenuOptions } from 'config/rightClickMenuOptions';
import { documentLinkList, navOptions, keyList } from "./config";
import { useStyles } from "./style";
import DocumentManagement from 'BIMNew/DocumentManagement';
import { selectConstVar, setPermission } from 'redux/slice/permissionSlice';

export default function NavbarContent({ pageType, overrideCurrentPageName, overrideGoBack }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const open = useSelector(selectMenuOpen);
  const storeContractData = useSelector(selectContractData);
  const history = useHistory();
  const { pathname } = useLocation();
  const sp = new URLSearchParams(location.search);
  const searchParam = sp.get("tab");
  const { contractId, type, docId } = useParams();
  const [currentPage, setCurrentPage] = useState('Overview');
  const [contractData, setContractData] = useState({});
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    getContractData(contractId);
  }, [contractId])

  async function getContractData(id) {
    try {
      if (!id) {
        dispatch(setContract({}));
        return
      }
      if (storeContractData?.id !== contractId) {
        const result = await contractHelper.getContract(id);
        dispatch(setContract(result.contract));
        setContractData(result.contract);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    function determinePageName() {
      if (overrideCurrentPageName) {
        setCurrentPage(overrideCurrentPageName);
        return
      }
      const pathParts = pathname.split('/');
      if (pathParts.length > 3) {
        const lastPart = pathParts[pathParts.length - 1];
        const pageList = keyList;
        const isCatPage = pageList.filter(option => option.key === lastPart);
        if (isCatPage.length > 0) {
          setCurrentPage(isCatPage[0].name);
        }
      } else {
        setCurrentPage("Overview");
      }
    }
    function determineCurrentPage() {
      const isCategory = matchPath(pathname, {
        path: "/contract/:contractId/:type",
        exact: true,
        strict: true
      });
      if (isCategory) {
        determinePageName();
        return;
      }
      const isDocLib = matchPath(pathname, {
        path: "/contract/:contractId/edms/folder/:folderId",
        exact: true,
        strict: true,
      })
      if (isDocLib) {
        setCurrentPage('Document Library');
      }
      determinePageName();
    }
    determineCurrentPage()
  }, [pathname]);

  function toggleNavigationMenu() {
    dispatch(toggleMenuOpen());
  }

  function decideLinkList(pageType) {
    if (pageType === "photo" || type === "risk-register") return documentLinkList.filter(link => link.name !== "History Log");
    if (type === "main-contractor" || type === "action" || type === "info" || type === "sent" || type === "edms") {
      return documentLinkList.filter(link => link.level <= 1)
    }
    return documentLinkList;
  }

  function renderLinkList() {
    switch (pageType) {
      case "category":
        return <></>
      case "document":
        const appliedList = decideLinkList(pageType, pathname.includes('edms'));
        return <>
          {appliedList.map((link, idx) =>
            <Link
              key={idx}
              to={link.to(pathname)}
              className={clsx(classes.anchorItem,
                searchParam === link.path ? classes.selected : "",
                !searchParam && idx === 0 ? classes.selected : "")
              }>
              {link.name}
            </Link>
          )}
        </>
      default:
        return null
    }
  }

  function goBack() {
    if (overrideGoBack) {
      history.push(overrideGoBack(contractId, type));
      return;
    }
    if (docId && pageType === "document") {
      if (pathname.includes('edms')) history.push(pathname.substring(0, pathname.indexOf('/document')));
      else history.push(`/contract/${contractId}/${type}?tab=docs`);
    } else {
      history.push(`/contract/${contractId}/edms`);
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.control}>
        {pageType !== "document" &&
          <div className={classes.navigation} onClick={toggleNavigationMenu}>
            <span>{currentPage}</span>
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </div>
        }
        <div className={clsx(pageType === "document" && classes.hidden)}>
          {<NavigationMenu open={open} contractData={contractData} />}
        </div>
        <div className={classes.anchorList}>
          {renderLinkList()}
        </div>
      </div>
    </div>
  );
}

function NavigationMenu({ open, contractData }) {
  const dispatch = useDispatch();
  const constVar = useSelector(selectConstVar);
  const { pathname } = useLocation();
  const { contractId } = useParams();
  const theme = useTheme();
  const props = { theme, open };
  const history = useHistory();
  const classes = useStyles(props);
  const [expand, setExpand] = useState({
    dwss: pathname.includes('edms') ? false : true,
    edms: pathname.includes('edms') ? true : false,
    pmi: pathname.includes('pmi') ? true : false,
    documentLib: false,
  });
  const [unseenCount, setUnseenCount] = useState({});
  const [accessPermission, setAccessPermission] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOptions, setMenuOptions] = useState([]);

  useEffect(() => {
    async function getUserPermission() {
      if (contractId) {
        const result = await permissionHelper.checkUserAccessPermission(contractId);
        setAccessPermission(result.user_permissions);
        dispatch(setPermission(result.user_permissions));
        return result.user_permissions;
      }
    }
    async function getUnseen(permissions) {
      const result = await Promise.all([
        edmsRegisterHelper.getUnseenCount({ contractId, for: "action" }),
        edmsRegisterHelper.getUnseenCount({ contractId, for: "information" }),
        edmsRegisterHelper.getUnseenCount({ contractId })
      ]);

      setUnseenCount({ action: result[0], info: result[1], allActions: result[2] });
    }

    async function init() {
      const p = await getUserPermission();
      dispatch(setLoading(false));
      await getUnseen(p);
    }

    try {
      dispatch(setLoading(true));
      init();

    } catch (e) {
      console.log(e);
    }
  }, [contractId]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  function renderDocumentLibrary(option, idx) {
    switch (option.key) {
      case "library":
        const rootOption = { ...option, id: contractData.rootFolderId }
        return <DocumentLibrary
          option={rootOption}
          isRoot={true}
          subItems={[]}
          key="lib"
          setAnchorEl={setAnchorEl}
          setMenuOptions={setMenuOptions}
        />
      case "docType":
        return <DocumentType option={option} isRoot={true} subItems={option.subItems} key="docType" />
      default:
        return <div key={idx}><Link className={classes.navigationLink} to={option.linkTo(contractId)} >{option.name}</Link></div>
    }
  }

  function renderDocumentManagement(option, idx) {
    switch (option.key) {
      case "library":
        const rootOption = { ...option, parentAutodeskId: getParentAutoDeskId() }
        return <DocumentManagement
          option={rootOption}
          isRoot={true}
          subItems={[]}
          key="lib"
          setAnchorEl={setAnchorEl}
          setMenuOptions={setMenuOptions}
        />
      case "docType":
        return <DocumentType option={option} isRoot={true} subItems={option.subItems} key="docType" />
      default:
        return <div key={idx}><Link className={classes.navigationLink} to={option.linkTo(contractId)} >{option.name}</Link></div>
    }
  }

  function getParentAutoDeskId() {
    switch (contractId) {
      case "1": return "urn:adsk.wipprod:fs.folder:co.A3TTfcoERe6xeVJQDXJmRg"; //DC-2018-06
      case "2": return "urn:adsk.wipprod:fs.folder:co.kDrzLg1jRNatION8a6e6JQ"; //DC-2018-07
      case "3": return "urn:adsk.wipprod:fs.folder:co.NPcQuPDnQN-B79jQjt9ePw"; //DE-2018-04
      case "4": return "urn:adsk.wipprod:fs.folder:co.tnyMXlWPTWeWa3c3LjoKvg"; //DE-2018-03
    }
  }

  function isEDMSAdmin() {
    if (accessPermission?.edms?.userType.includes("admin")) {
      return true;
    }
    return false;
  }

  function renderNavItem(option, idx) {
    if (!option.permissionKey || (option.permissionKey && checkPermissionGrant(accessPermission?.[option.permissionKey]?.permissions))) {
      switch (option.type) {
        case "group":
          if (option.key === "admin" && !authHelper.checkAdminPermission()) return;
          if (option.key === "document registration" && !checkPermissionGrant(accessPermission?.[option.permissionKey]?.permissions, 'edit')) return;

          return <>
            <div className={classes.groupTitle}>
              <div className={classes.btnControl} onClick={() => setExpand({ ...expand, [option.key]: !expand[option.key] })}>{expand[option.key] ? "-" : "+"}</div>
              {option.linkTo
                ? <Link className={classes.underline} to={option.linkTo(contractId)} onContextMenu={(e) => onContextMenu(e, option.key)}>{option.name}</Link>
                : <div className={classes.underline} onContextMenu={(e) => onContextMenu(e, option.key)}>{option.name}</div>
              }
            </div>
            <div className={clsx(classes.group, expand[option.key] ? classes.expanded : classes.collapsed)}>
              {option.subGroup.map((subOption, j) => <Fragment key={j}>{renderNavItem(subOption, j)}</Fragment>)}
            </div>
          </>
        case "docLib":
          if (!checkPermissionGrant(accessPermission?.[option.permissionKey]?.permissions, 'view')) return;
          return option.subGroup.map((subOption, j) => <Fragment key={j}>{renderDocumentLibrary(subOption, j)}</Fragment>);
        case "docManagement":
          return option.subGroup.map((subOption, j) => <Fragment key={j}>{renderDocumentManagement(subOption, j)}</Fragment>);
        case "external":
          return <a className={classes.navigationLink} href={option.linkTo(constVar?.[option.key])} target="_blank" rel="noreferrer noopener">{option.name}</a>
        case "blank":
          return <a className={classes.navigationLink} href={option.linkTo(contractId)} target="_blank" rel="opener">{option.name}</a>
        case "search":
          return <div key={idx}><Link className={classes.navigationLink} to={{ pathname: option.linkTo(contractId) }}>{option.name}</Link></div>
        default:
          return <div key={idx}>
            <Link
              className={classes.navigationLink}
              to={option.linkTo(contractId)}
              onContextMenu={(e) => option.isModule ? onContextMenu(e, option.key) : () => { return }}>
              {option.name}
              {option.renderExtra && option.renderExtra(unseenCount?.[option.key])}
            </Link>
          </div>
      }
    }
    return;
  }

  function checkPermissionGrant(permissions, checkType) {
    if (!permissions) return false;
    if (!checkType && permissions.find(o => (o.code === "view" || o.code === "edit"))) return true; // general check
    if (permissions.find(o => o.code === checkType)) return true;
    else return false;
  }

  async function onContextMenu(e, key) {
    e.preventDefault();
    const options = await moduleMenuOptions(handleClose, history, contractId, key)
    await setMenuOptions(options);
    setAnchorEl(e.target);
  }

  return (
    <div className={clsx(classes.navigationContainer, open ? classes.expanded : classes.collapsed)}>
      <div className={classes.navigationMenu}>
        {navOptions.map((option, idx) =>
          <Fragment key={idx}>{renderNavItem(option, idx)}</Fragment>
        )}
      </div>
      <SimpleMenu
        options={menuOptions}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
    </div>
  );
}