export const tableConfig = [
  { id: "logNumber", label: "Log No." },
  {
    id: "inOut", label: "In/Out", type: "Select", options: [
      {
        label: "Incoming",
        value: "in",
      },
      {
        label: "Outgoing",
        value: "out",
      },
    ],
    insertBlank: "All"
  },
  { id: "letterDate", label: "Letter Date", type: "Date" },
  { id: "receiveDate", label: "Receive/Dispatch", type: "Date", },
  { id: "subject", label: "Subject", size: 12 },
  { id: "pdfContent", label: "Content", size: 12, hideInTable: true },
  { id: "fileId", type: "fileNumber", label: "File No." },
  { id: "docType", label: "Document Type", optionKey: "docType", type: "Select", insertBlank: "All" },
  { id: "fromCompany", label: "From Company", optionKey: "company", type: "Autocomplete", },
  { id: "toCompany", label: "To Company", optionKey: "company", type: "Autocomplete" },
  { id: "letterRef", label: "Letter Ref.", },
  { id: "a1Drawing", label: "Sketch / Drawing No.", },
  { id: "repliedBy", label: "Replied By", optionKey: "letterRef", type: "Autocomplete" },
  { id: "replyTo", label: "Reply To", optionKey: "letterRef", type: "Autocomplete" },
  { label: "" },
];